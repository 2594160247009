<script>
import MasterDetail from "@/components/master-detail.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    MasterDetail
  },
  data() {
    return {
      opts: {
        businessUnits: [],
        niveisHierarquicos: [],
        icons: [
          {
            id: true,
            element: '<i aria-hidden="true" class="v-icon notranslate mdi mdi-check-circle theme--light" style="color: #135153"></i>'
          },
          {
            id: false,
            element: '<i aria-hidden="true" class="v-icon notranslate mdi mdi-close-circle theme--light" style="color: #EC4C47"></i>'
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    cols() {
      return [
        {
          key: "usuario",
          name: "Possui acesso?",
          type: this.$fieldTypes.HTML,
          rel: { to: 'icons', key: 'id', name: 'element' },
          hideInform: true,
          width: '50px'
        },
        {
          key: "nivel_hierarquico_id",
          name: "Nível hierarquico",
          rules: [{ rule: "required" }],
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: 'niveisHierarquicos', key: 'id', name: 'nome' },
          colSize: 6,
        },
        {
          key: "business_unit_id",
          name: "Business Unit",
          rules: [{ rule: "required" }],
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: 'businessUnits', key: 'id', name: 'label' },
          colSize: 6,
        },
        {
          key: "nome",
          name: "Nome",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }]
        },
        {
          key: "email",
          name: "E-mail",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }]
        }
      ];
    },
    customResource() {
      const resource = this.apiResource(`v1/ativacaoped/${this.clientId}/responsaveis`);
      return {
        ...resource,
        async get(...args) {
          const { data, ...rest } = await resource.get(...args);
          return {
            ...rest,
            data: data.map((d) => ({ ...d, usuario: !!d.usuario_id }))
          }
        }
      }
    }
  },
  async created() {
    const promises = [
      this.getBusinessUnits(),
      this.getNiveisHierarquicos()
    ];
    await Promise.all(promises);
  },
  methods: {
    async getBusinessUnits() {
      try {
        const { get } = this.apiResource(`v1/ativacaoped/${this.clientId}/business-units/selecao`);
        const response = await get();
        this.opts.businessUnits = response.map((bu) => ({ ...bu, label: `${bu.nome} (${bu.divisao})` }));
      } catch (error) {
        this.notify(error);
        this.opts.businessUnits = [];
      }
    },
    async getNiveisHierarquicos() {
      try {
        const { get } = this.apiResource(`v1/ativacaoped/${this.clientId}/nivel-hierarquicos/selecao`);
        this.opts.niveisHierarquicos = await get();
      } catch (error) {
        this.notify(error);
        this.opts.niveisHierarquicos = [];
      }
    },
  }
};
</script>

<template>
  <MasterDetail
    ref="masterDetail"
    :canDelete="false"
    :cols="cols"
    :customResource="customResource"
    formTitle="Cadastro de responsaveis"
    :hasExportCSV="false"
    :hasExportXLSX="false"
    :opts="opts"
    serverPagination
  ></MasterDetail>
</template>
